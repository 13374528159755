
import { defineComponent } from 'vue';

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/kTDatatableLeft.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'new-certification-dashboard',
  components: {
    Datatable,
  },
  data() {
    return {
      batch: {
        tranche_id: '',
      },
      load: false,

      tableData: [],
      tableHeader: [
        {
          name: 'Sl',
          key: 'sl',
          sortable: true,
          width: '5px',
        },
        {
          name: 'Logo',
          key: 'logo',
          sortable: true,
        },
        {
          name: 'Training Partner',
          key: 'training_partner',
          sortable: true,
        },
        {
          name: 'Course',
          key: 'course',
          sortable: true,
        },
        {
          name: 'Batch',
          key: 'batch',
          sortable: true,
        },
        {
          name: 'Trainee Enrolled',
          key: 'trainee_enrolled',
          sortable: true,
        },

        {
          name: 'Trainee Certified',
          key: 'trainee_certified',
          sortable: true,
        },
        {
          name: 'Female Certified',
          key: 'female_certified',
          sortable: true,
        },
        {
          name: 'Dues over 30 days',
          key: 'dues',
          sortable: true,
        },
      ],
      tranches_info: [],
      list: [],
      dashboardData: [],
      loading: false,
      componentKey: 0,
      api_url: '',
      showDashboard: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getTranches();
    this.api_url = this.VUE_APP_API_URL;
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getDash() {
      this.loading = true;
      let entity_id = '';
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      ApiService.get(
        'certificate/dash?tranche_id=' +
          this.batch.tranche_id +
          '&entity_id=' +
          entity_id +
          '&training_institute_id=' +
          institute_info_id
      )
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.dashboardData = response.data.data;
          this.list = response.data.data.list;
          this.showDashboard = true;
          this.componentKey += 1;
        })
        .catch((response) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getTranches() {
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          this.tranches_info = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    courseInfo() {
      this.courseInfoData = true;
    },
    showDash() {
      this.showDashboard = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {},
});
